import { createStore } from 'vuex'

export default createStore({
  state: {
    backendIP: '7b25-46-253-188-135.eu.ngrok.io',
    // backendIP: 'localhost:8443',
    // backendIP: 'lsquizz.jcloud-ver-jpc.ik-server.com',
    questions: [],
    steps: [],
    currentStep: 0,
    score: 0,
    respBegin: 0,

    player: '',  // NOTE: CHANGED, might bring pb: was email address, is now same object as in back
    ws: null,    // websocket
    errorMsg: [], // table of strings to be displayed each in a paragraph
    ready: false,  // indicates when the player is registered and the ws is synchronized.
    stats: {players: null, tables: null},
    nbPlayers: 0,
    wsNb: 0,
    recoTimeout: 0,
    wsTimeout: null
  },
  getters: { // NOTE: don't modify getters (object's members are fine but better use a mutation)
    currentStepStr: (state) => state.steps[state.currentStep] || '',
    isQuestion: (state, getters) => getters.currentStepStr?.startsWith('qu'),
    showResp: (state, getters) => getters.currentStepStr?.[2] === 'R' ,
    isAnswer: (state, getters) => getters.currentStepStr?.startsWith('rep'),
    showStats: (state, getters) => getters.currentStepStr?.startsWith('stat'),
    showQuNb: (state, getters) => getters.currentStepStr?.startsWith('qNb'),
    currentQuestion (state, getters) { // Note: returns the question object on both questions phases (qu[eR]\d)
      return getters.isQuestion ? state.questions[getters.curQuestionNb] : {}
    },
    currentAnswer (state, getters) { // Note: returns the question object on answers phase (rep\d)
      return getters.isAnswer ? state.questions[getters.curQuestionNb] : {}
    },
    currentBoth (state, getters) { // Note: returns the question object on all phases
      return state.questions[getters.curQuestionNb] ?? {}
    },
    curRep: (state, getters) => state.player.rep[getters.curQuestionNb],  // NOTE: is a table
    curTime: (state, getters) => state.player.time[getters.curQuestionNb] || 0,
    canRegister: (state) => state.currentStep === 0,
    curQuestionNb: (state, getters) => getters.currentStepStr?.substring(3), // Note: this gets the number after "que", "quR", "rep" (~ or null [not yet, don't use it in non-question/resp. phase.])
  },
  mutations: {
    setStep (state, newStep) {
      state.currentStep = newStep
    },
    setState (state, {steps, questions}) {
      state.questions = questions
      state.steps = steps
    },
    setDynState (state, {nbPlayers, currentStep, curQuestionNb}) {
      state.currentStep = currentStep
      state.nbPlayers = nbPlayers
      state.curQuestionNb = curQuestionNb
    },
    setPlayer (state, player) {state.player = player},
    setRespBegin (state) {state.respBegin = Date.now() },
    setWs (state, ws) {state.ws = ws},
    setErrMsg (state, msg) {state.errorMsg = msg },
    setResp (state, resp) { this.getters.currentQuestion.resp = resp }, // NOTE: player's answer (not used)
    setSol (state, sol) { this.getters.currentQuestion.sol = sol },     // true solution
    setReady (state) {state.ready = true}

  },
  actions: {
    async fetchState (ctx) {
      // NOTE this is splitted because the steps and questions won't change, so it can be cached by the browser.
      const resp = await (await ctx.dispatch('sendGetToBack', '/state')).json()
      ctx.commit('setState', resp)
      const resp2 = await (await ctx.dispatch('sendGetToBack', '/dynState')).json()
      ctx.commit('setDynState', resp2)
    },
    // NOTE: those functions returns Response obj (fetch api), need to parse it  [.text() / .json() ]

    async postJsonToBack (ctx, {url, body}) {
      return await fetch('https://'+ ctx.state.backendIP + url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body
      })
    },
    async sendGetToBack (ctx, url) {
      return await fetch('https://'+ ctx.state.backendIP + url )
    },

    async createWs (ctx, {type = 'players', timeout = 250} = {}) {
      if (ctx.state.ws?.readyState <= 1) return
      ctx.dispatch("fetchState")
      try {
        if (ctx.state.ws)
          ctx.commit('setWs', null)
        ctx.state.recoTimeout = Date.now()
        const ws = await new WebSocket("wss://"+ ctx.state.backendIP +"?type="+type);
        ws.wsNb = ctx.state.wsNb ++
        console.log('created ws:', ws, ws.wsNb )

        ctx.commit('setWs', ws)

        ws.onerror = () => {
          console.log('timeout in error', timeout, 'ws nb:', ws.wsNb)
          if (ctx.state.currentStep < 103) {
            setTimeout(() => ctx.dispatch('createWs', {type, timeout: 2 * timeout}), timeout)
          }
        }
        // NOTE: TEST to debug
        ws.onopen = () => {
          console.log('readyState just after opening evt:', ws.readyState, 'opened ws nb:', ws.wsNb)

          if (localStorage.mail && type === 'players')// && ! this.canRegister)
            ctx.dispatch('syncWs')

          ws.onclose = () => {
            console.log('closing ws n°', ws.wsNb, ws.readyState)
            console.log('timeout in close', timeout)
            if (ctx.state.currentStep < ctx.state.nbSteps -1 ) {
              timeout = ctx.state.recoTimeout + 300000 <= Date.now() ? 250 : timeout + 500
              setTimeout(() => ctx.dispatch('createWs', {type, timeout}), timeout)
            }
          }
          ctx.dispatch("keepWsAlive")
        }

        ws.onmessage = type === 'players' ? (evt) => ctx.dispatch('_onMsgPlayer', evt) : (evt) => ctx.dispatch('_onMsgProj', evt)
      }
      catch (e) {
        console.error(e)
        console.log('timeout in catch', timeout)
        setTimeout (() => ctx.dispatch('createWs', {type, timeout: 2 * timeout}), timeout )
      }
    },
    async syncWs (ctx) {
      // sending msg to sync ws and user to back (check that ws is open first)
      if (ctx.state?.ws?.readyState === WebSocket.OPEN ) {
        ctx.state.ws.send( JSON.stringify( {setUser: localStorage.mail ? localStorage.mail.toLowerCase() : document.getElementById('email').value.toLowerCase() } ))
        ctx.dispatch("keepWsAlive")
        console.log('syncWs setUser')
      }
      else {
        window.location.reload()
        console.log('syncWs reload')
      }
    },
    displayError ({ commit }, errMsgTab) {
      commit("setErrMsg", errMsgTab );
      const box = document.querySelector('.error')
      box.classList.replace('hidden', 'visible')
      setTimeout (() => box.classList.replace( 'visible', 'hidden'), 3000)
    },
    _onMsgPlayer (ctx, evt) {

      const data = JSON.parse(evt.data)
      console.log('ws event:', evt, '\ndata:', data, 'ts:', evt.timeStamp)

      // NOTE: testing evt content to decide what to do. Step change should be the first of the checks for perf.
      if (data.currentStep >= 0) {
        ctx.commit("setStep", data.currentStep)
        if (ctx.getters.showResp)
          ctx.commit('setRespBegin')
        // else if (data.resp) {ctx.commit('setResp', data.resp)}
        else if (ctx.getters.isQuestion && ! ctx.getters.showResp) {
          // remove chosen answer  ----> nope fine, ça stocke la rep dans la question courante =)
        }
        else if (ctx.getters.isAnswer) {
          //!\ NOTE: data.curQuNb arrives as a number, the getter one is a str !!!
          // console.log(this.player, ctx.state.player.score[data.curQuestionNb], this.player?.score?.[data.curQuestionNb])
          // ctx.state.player.cumScore += ctx.state.player.score[data.curQuestionNb] ?? 0
          ctx.state.player.cumScore = Object.values(ctx.state.player.score).reduce((a,b) => a+(b || 0) , 0)
        }
        if (ctx.getters.currentStepStr === 'end!') {
          localStorage.clear()
        }

      }
      else if (data.error) {
        // NOTE: this takes a simple string and cuts it at newlines into a table
        ctx.dispatch('displayError', data.error.split('\n'))
      }
      else if (data.ping) {
        ctx.state.ws.send(JSON.stringify({pong: "nope", mail: data.mail}))
        ctx.dispatch("keepWsAlive")
        // console.log("pinged!")
      }
      else if (data.ready) {
        // set state.ready
        ctx.commit('setPlayer', data.you )
        ctx.commit('setReady')
      }
      else if (data.deleteStorage) {
        localStorage.clear()
        window.location.reload()
      }

      ctx.dispatch("keepWsAlive")
    },
    _onMsgProj (store, evt) {
      const data = JSON.parse(evt.data)
      console.log('ws event in proj:', evt, '\ndata:', data, 'ts:', evt.timeStamp)

      // NOTE: step change
      if (data.currentStep >= 0) {
        store.commit("setStep", data.currentStep)
        if (store.getters.isQuestion && ! store.getters.showResp) {
          store.getters.currentBoth.nbResp = 0
          // this.currentQuestion.usersChoices = []
          // this.usersChoices = []
        }

        else if (data.usersChoices) {
          store.getters.currentAnswer.usersChoices = data.usersChoices

          console.log('current Answer updated with choices:', store.getters.currentAnswer)
          // note: nbResp is set below
        }

        else if (data.globalBests) {
          // this.currentAnswer['currentBests'] = data.currentBests
          store.state.stats.players = data.globalBests
          // this.currentAnswer['currentBestsTbl'] = data.currentBestsTbl
          store.state.stats.tables = data.globalBestsTbl
        }

      }
      // else if (data.questions) // == if state arrives on WS
      //   store.commit("setState", data)
      /*else if (data.player) {
        store.commit("setPlayer", data.player)
        // TODO send sync msg
      }*/
      if (data.nbPlayers) {
        // console.log(store.state.nbPlayers, data.nbPlayers)
        store.state.nbPlayers = data.nbPlayers
      }
      if (data.nbResp) {
        store.getters.currentBoth.nbResp = data.nbResp
      }

      store.dispatch("keepWsAlive")
    },
    keepWsAlive (ctx) {
      clearTimeout( ctx.state.wsTimeout );
      if (ctx.state.ws.readyState < 2 ) {
        ctx.state.wsTimeout = setTimeout(() => {
          ctx.state.ws.send("{\"keepAlive\":\"beep\"}")
          console.log('maintaining ws open')
          ctx.dispatch("keepWsAlive")
        }, 290000)
      }
    }
  },
  modules: {
  }
})
